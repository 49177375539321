
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';

@Component({
  components: {},
})
export default class LynusQrCode extends Vue {
  @Action('projects/resetQR') resetQR!: (projectId: string) => Promise<void>;
  @Action('projects/disableQR') disableQR!: (projectId: string) => Promise<void>;
  @State('projects') projectsState!: IProjectsState;

  qrOptions = {
    errorCorrectionLevel: 'Q',
  };
  showDisableField = false;

  get project() {
    return this.projectsState.project;
  }
  get qrcodeValue() {
    return this.$route.params.id;
  }

  get controllerProjectId() {
    return this.project?.meta?.controller?.projectId ?? '';
  }

  get isJoinable() {
    return this.project?.joinable ?? false;
  }

  handleDisable() {
    this.disableQR(this.$route.params.id);
    this.showDisableField = false;
  }

  resetQRCode() {
    this.resetQR(this.$route.params.id);
    this.showDisableField = true;
  }
  downloadQRCode() {
    const canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 500;

    const ctx = canvas.getContext('2d')!;

    // Render QR code onto canvas
    const qrCodeImg = (this.$refs.qrCode as any).firstChild;
    ctx.drawImage(qrCodeImg, 0, 0, 500, 500);

    // Add Project ID from meta - Draw this first
    const text = this.controllerProjectId;

    const textSize = 36;
    ctx.font = `${textSize}px Arial`;
    ctx.fillStyle = 'black';
    const textWidth = ctx.measureText(text).width;
    const textX = (canvas.width - textWidth) / 2;
    const textY = textSize;
    ctx.fillText(text, textX, textY);

    // Define the circle for the logo
    const logoWidth = 150;
    const logoHeight = 150;
    const logoX = (canvas.width - logoWidth) / 2;
    const logoY = (canvas.height - logoHeight) / 2;
    const radius = logoWidth / 2;
    const enlargedRadius = radius * 1.05; // 10% bigger

    // Draw the white circle with the enlarged radius
    ctx.beginPath();
    ctx.arc(logoX + radius, logoY + radius, enlargedRadius, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();

    // Clip to the original circle size and then draw the logo
    ctx.beginPath();
    ctx.arc(logoX + radius, logoY + radius, radius, 0, 2 * Math.PI);
    ctx.clip();

    // Render logo onto canvas
    const logoImg = this.$refs.qrLogoImg as any;
    ctx.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

    // poroject name for file name
    const projectName = this.project.name.replaceAll(' ', '_');

    // Convert canvas to image and download
    const link = document.createElement('a');
    link.download = `qrcode_${projectName}.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  }

  mounted() {
    if (this.project.joinable) {
      // if project is joinable, show disable field
      this.showDisableField = true;
    } else {
      // if project is not joinable, hide disable field
      this.showDisableField = false;
    }
  }
}

