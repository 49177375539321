
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { NavigationGuardNext, Route } from 'vue-router';
import { cloneDeep, debounce, isEmpty, isEqual } from 'lodash';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageProjectDuplicateWindow
  from '@/ui/components/components/ManageProjectDuplicateWindow.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import ProjectTagsField from '@/ui/components/components/ProjectTagsField.vue';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { Validation } from '@/ui/mixins/validation';

import { IProjectsState } from '@/store/modules/projects/types';
import { IMPCState } from '@/store/modules/mpc/types';
import {
  envAppShowProjectTypeSelection,
  envEnableControllerSettings,
  envSettingsShowWeatherService,
} from '@/utils/env';
import { IRoomsState } from '@/store/modules/rooms/types';
import { IAppState } from '@/store/modules/app/types';
import { IVariableListState } from '@/store/modules/variables/types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { AllBatteryTypes } from '@/utils/batteryTypes';
import { PacketCallback } from 'mqtt';
import { IProject, IProjectMQTTInfo } from '@/types/project.types';
import LynusQrCode from '@/ui/views/Project/Settings/LynusQrCode.vue';
import { IRule } from '@/types/rules.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IMPCWeatherServiceRequestBody, IMLModel } from '@/types/mpc/mpc.types';
import LocationService from '@/services/LocationService';
import { IReportBox, IWeatherLocation } from '@/types/app.types';
import { IMember } from '@/types/members.types';
import { IPublishRecord } from '@/types/mqtt.types';
import { plcVersionDate } from '@/utils/versionManagementUtils';
import {
  BatteryType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { PlcPerformanceCategorie } from '@/utils/plcPerformanceLimits';

type IGetLocationWithDelay = (place: string) => void;

Component.registerHooks(['beforeRouteLeave']);

/**
 * Component that shows general settings tab
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    ModalWindow,
    LynusQrCode,
    LeaveConfirmationModal,
    DeleteModalForm,
    ManageProjectDuplicateWindow,
    ProjectTagsField,
  },
})
export default class General extends mixins(Validation) {
  @State('projects') projectsState!: IProjectsState;
  @State('mpc') mpcState!: IMPCState;
  @State('rooms') roomsState!: IRoomsState;
  @State('app') appState!: IAppState;
  @State('variables') variablesState!: IVariableListState;
  @State('measurements') measurementsState!: IVariablesState;
  @Getter('members/currentMember') currentMember!: IMember;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Mutation('projects/setProjectId') setProjectId!: (payload: string) => void;
  @Mutation('projects/setProject') setProject!: (payload: string) => void;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;
  @Action('projects/deleteProject') deleteProject!: (id: string) => Promise<void>;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  @Action('app/postAsset') postAsset!: (asset: any) => Promise<any>;
  @Action('app/fetchWeather') fetchWeather!: (location: IWeatherLocation) => Promise<void>;
  @Action('mpc/fetchMPCWeatherStatus') fetchMPCWeatherStatus!: () => Promise<void>;
  @Action('mpc/activateWeatherService') activateWeatherService!: (payload: IMPCWeatherServiceRequestBody) => Promise<void>;
  @Action('mpc/deactivateWeatherService') deactivateWeatherService!: () => Promise<void>;
  @Action('rules/loadRules') loadRules!: (project_id: string) => Promise<IRule[]>;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('mqttClient/publish') publishStore!: ({ records, callback }: {
    records: IPublishRecord[];
    callback: PacketCallback;
  }) => void;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('mpc/deleteMPC') deleteMPC!: (mpcId: IMLModel) => void;

  isWeatherServiceShow = envSettingsShowWeatherService;
  enableControllerSettings = envEnableControllerSettings;

  localProject: any = null;
  projectSnapshot: any = null;
  valid = false;
  uploadButtonClicked = true;

  batteryTypesItems = Object.values(AllBatteryTypes);
  model: IWeatherLocation | null = null;
  searchPlaces: string | null = null;
  placesArr: IWeatherLocation[] = [];
  getLocationWithDelay: IGetLocationWithDelay | null = null;
  isCustomLabel = envAppShowProjectTypeSelection;
  isDuplicating = false;

  fileObject = { name: '' };

  get plcPerformanceItems() {
    return [
      {
        text: this.$t('uiComponents.settings.general.form.performanceLevelItems.low'),
        value: PlcPerformanceCategorie.low,
      },
      {
        text: this.$t('uiComponents.settings.general.form.performanceLevelItems.medium'),
        value: PlcPerformanceCategorie.medium,
      },
      {
        text: this.$t('uiComponents.settings.general.form.performanceLevelItems.high'),
        value: PlcPerformanceCategorie.high,
      },
    ];
  }

  get project() {
    return this.projectsState.project as IProject;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get mqttInfo() {
    return this.projectsState.mqttInfo;
  }

  get isWeatherServiceActive() {
    return this.mpcState.isWeatherServiceActive;
  }

  get isLocationEqual() {
    const location = this.projectSnapshot?.meta?.location ?? null;
    return isEqual(location, this.model);
  }

  get isLocalOriginProjectEqual() {
    const isProjectDataEqual = isEqual(this.projectSnapshot, this.localProject);
    return this.isLocationEqual && isProjectDataEqual;
  }

  get currentBatterySystemType() {
    return this.localProject.meta.controller.batterySystemType;
  }

  @Watch('searchPlaces')
  async onSearchPlaces(val: string | null) {
    const isEqualToSaved = this.project.meta?.location?.display_name === val;
    if (val && val.length > 2 && !isEqualToSaved) {
      this.getLocationWithDelay?.(val);
    }
  }

  hasMultipleBatteryTypes(system: string) {
    return [BatteryType.DEYE.toString()].includes(system);
  }

  setLocation(location: IWeatherLocation) {
    this.model = { ...location };
    this.placesArr.push({ ...location });
  }

  handleChipsChange(value: string[]) {
    this.localProject.meta.tags = value;
  }

  handleNavigationGuard(isDisable: boolean) {
    this.isDuplicating = isDisable;
  }

  createProjectSnapshot() {
    this.projectSnapshot = cloneDeep(this.localProject);
  }

  changePlcDate(event: any) {
    this.localProject.meta.plcLastUpdate = new Date(event).toISOString();
    this.localProject = { ...this.localProject };
  }

  /**
   * Updates current project
   */
  async handleGeneralSettings() {
    const { password, username } = this.mqttInfo as IProjectMQTTInfo;
    const latitude = Number(this.model?.lat);
    const longitude = Number(this.model?.lon);
    const wasLocationChanged = this.project.meta.location?.display_name !== this.model?.display_name;

    // if location is changed deactivate weather service for backend to update location correctly
    if (wasLocationChanged) {
      await this.deactivateWeatherService();
    }

    await this.updateProject({
      ...this.localProject,
      meta: {
        ...this.localProject.meta,
        location: this.model,
      },
    });
    this.createProjectSnapshot();

    // if location is changed activate weather service for backend to update location correctly
    if (wasLocationChanged) {
      await this.activateWeatherService({
        latitude,
        longitude,
        username,
        password,
      });
    }

    await this.fetchWeather(this.model as IWeatherLocation);
  }

  /**
   * Removes current project and redirect to Home page
   */
  async removeProject() {
    await this.fetchMPCListByProject();
    const mpcList: any = cloneDeep(this.mpcState.mpcControllers).toJS();
    if (!isEmpty(mpcList)) {
      await Promise.all(
        Object.values(mpcList).map(async (mpcObject: any) => {
          await this.deleteMPC(mpcObject);
        }),
      );
    }
    if (this.isWeatherServiceActive) {
      await this.deactivateWeatherService();
    }
    await this.deleteProject(this.$route.params.id);
    await this.$router.push('/');
  }

  resetButtonClickedVariable() {
    this.uploadButtonClicked = false;
  }

  /**
   * Upload file and update current project
   */
  async handleFileUpload() {
    this.uploadButtonClicked = true;
    if (this.fileObject) {
      if (this.fileObject.name.lastIndexOf('.') <= 0) {
        return;
      }
      const result = await this.postAsset(this.fileObject);
      if (!result) return;

      await this.updateProject({
        ...this.localProject,
        ...(result && {
          meta: { ...this.localProject.meta, imageId: result },
        }),
      });
      this.createProjectSnapshot();
    }
  }

  /**
   * Activate, deactivate weather service
   * @param {object} e mouse event object
   */
  async handleWeatherService(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const { password, username } = this.mqttInfo as IProjectMQTTInfo;
    const latitude = Number(this.model?.lat);
    const longitude = Number(this.model?.lon);

    if (!this.isWeatherServiceActive) {
      await this.activateWeatherService({ latitude, longitude, username, password });
    } else {
      await this.deactivateWeatherService();
    }
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (!this.project || this.isDuplicating) next(); // if project was deleted not show confirmation dialog

    if (from.name === 'General' && !this.isLocalOriginProjectEqual) {
      await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction(to, next);
    } else if (!this.uploadButtonClicked && this.fileObject.name !== '') {
      // in case that the user has a new image but did not click the upload button we show the confirmation dialog
      await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction(to, next);
    } else next();
  }

  // reload project data
  reloadLocalProject() {
    this.localProject = cloneDeep(this.project);
  }

  async created() {
    this.localProject = cloneDeep(this.project);
    if (!this.project.meta.expert) {
      this.$set(this.localProject.meta, 'expert', {
        name: '',
        street: '',
        city: '',
        zip: '',
        country: '',
        email: '',
        phone: '',
        employeeName: '',
      });
    }
    if (!this.project.meta.controller) {
      this.$set(this.localProject.meta, 'controller', {
        type: '',
        serialNumber: '',
        projectId: '',
        batterySystemType: '',
        plcPerformanceLevel: '',
      });
    }
    if (!this.project.meta.tags) this.localProject.meta.tags = [];

    if (this.project.meta.plcLastUpdate) {
      this.localProject.meta.plcLastUpdate = this.project.meta.plcLastUpdate;
    } else {
      this.localProject.meta.plcLastUpdate = this.project.created_at;
    }
    // fetch location with 1sec delay
    this.getLocationWithDelay = debounce(async (place) => {
      this.placesArr = await LocationService.fetchLocations(place);
    }, 1000);

    if (this.project.meta?.location) this.setLocation(this.project.meta?.location);

    this.$nextTick(() => {
      this.createProjectSnapshot();
    });

    await Promise.allSettled([
      this.fetchMPCWeatherStatus(),
      this.loadRules(this.$route.params.id),
      this.fetchVariables(this.$route.params.id),
      this.fetchMeasurements(this.$route.params.id),
      this.fetchRooms(this.$route.params.id),
    ]);
  }
}
