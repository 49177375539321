
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DuplicationNavigation extends Vue {
  @Prop({ default: false }) disablePrevious!: boolean;
  @Prop({ default: 'fa-chevron-right' }) nextIcon!: string;
  previous() {
    this.$emit('previous');
  }
  next() {
    this.$emit('next');
  }
}

