
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmailAction from '@/ui/components/modals/actions/EmailAction.vue';
import WebhookAction from '@/ui/components/modals/actions/WebhookAction.vue';
import AlertAction from '@/ui/components/modals/actions/AlertAction.vue';
import api from '@/services/api';
import { State, Action } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';

/**
 * Component that shows project connectivity status
 */
@Component({
  components: {
    email: EmailAction,
    webhook: WebhookAction,
    alert: AlertAction,
  },
})
export default class ConChecker extends Vue {
  @Prop({ default: false }) isInModal!: boolean;
  @State('projects') projectsState!: IProjectsState;
  @Action('projects/updateProject') updateProject!: (project: any) => Promise<void>

    conModel: any = {
      actions: [],
      enabled: false,
    };

    get project() {
      return this.projectsState.project;
    }

    @Watch('conModel', { deep: true })
    handleChange(val: any) {
      this.$emit('changeObject', val);
    }

    created() {
      this.conModel = JSON.parse(JSON.stringify(this.project.connectivity));
    }

    /**
      * addAction pushes a new action for a specific type to the actions list
      * @param {string} type action type
      */
    addAction(type: string) {
      const action = { 'type': type, params: {} };

      switch (type) {
        case 'webhook':
          action.params = {
            headers: {
              'content-type': 'application/json',
            },
            method: 'POST',
            body: '',
            url: '',
          };
          break;
        case 'email':
          action.params = {
            recipients: ['example@mail.com'],
            subject: '',
            body: '',
          };
          break;
        case 'alert':
          action.params = {
            type: 0,
            body: '',
          };
          break;
      }

      this.conModel.actions.push(action);
    }

    /**
      * delete the Action based on the Index passed from the Button
      * @param {number} index action index
      */
    deleteActionByIndex(index: number) {
      this.conModel.actions.splice(index, 1);
    }

    async submitChanges() {
      const localProject = JSON.parse(JSON.stringify(this.project));
      localProject.connectivity = this.conModel;
      await this.updateProject(localProject);
    }
}
